import * as _ from 'lodash';
import {BehaviorSubject} from 'rxjs';

export const MBL_TYPE_FORM_CAPTURE_V2 = 'mabble.data.form-capture';

/**
 * model Object Entity; starts the model lifecycle.
 *
 * Deserialise any embedded payload data.
 */
export const moe = (oe: ObjectEntity<any>): ObjectEntity<any> => {
  const deserialised = (oe.data && (JSON.parse(oe.data) || {})) || {};
  const result = oe;
  result.value = undefined;
  result.data = undefined;
  Object.keys(deserialised).forEach((moek) => {
    if (moek !== 'id' && moek !== '_mblVersion' && moek !== 'value' && moek !== 'data') { // worried that excluding data/value is bad.
      result[moek] = deserialised[moek];
    }
  });
  return result;
};


export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}


export interface Identifiable {
  chosen?: boolean;
  description?: string;
  id?: number;
  _mblId?: string;
  name?: string;
  type?: string;
}

export interface Named extends Identifiable {
  other?: any;
}


export interface OE extends Identifiable {
  _mblId?: string;
  id?: number;

  createdBy?: any;
  createdOn?: any;
  modifiedBy?: any;
  modifiedOn?: any;

  context?: string;
  identifier?: string;

  type?: string;
  contentType?: string;
}

export class UserInterfaceConfiguration {
  debugEnabled: boolean;
  sendmsgEnabled: boolean;
  defaultEditor: string;

  constructor(options?: any) {
    this.debugEnabled = options && options.debugEnabled || this.debugEnabled;
    this.sendmsgEnabled = options && options.sendmsgEnabled || this.sendmsgEnabled;
    this.defaultEditor = options && options.defaultEditor || 'plain';
  }
}


// --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --->
/**
 * The root mabble-object-entity definition.
 * This class typically, but not always, corresponds to a row on the oe_oris db table
 *
 * Many mini mabble-module types will extend from this base class
 */
export class ObjectEntity<T> implements OE {
  id?: number;
  _mblId: string;
  _mblVersion: number;

  createdBy?: string;
  createdOn?: number;
  modifiedBy?: string;
  modifiedOn?: number;

  context?: string;
  identifier?: string;

  type?: string;
  contentType?: string;

  data?: any;

  // not in the API
  hashed?: string;
  options?: any;
  properties?: any;
  value?: T; // does not travel across wire.

  constructor(options?: any, clear_mblId: boolean = false) {
    this.id = options && options.id || this.id;
    this._mblId = options && options._mblId || uuidv4();
    if (clear_mblId) {
      this._mblId = undefined;
    }
    this.createdBy = options && options.createdBy || this.createdBy;
    this.createdOn = options && options.createdOn || this.createdOn || (new Date()).getTime();
    this.modifiedBy = options && options.modifiedBy || this.modifiedBy;
    this.modifiedOn = options && options.modifiedOn || this.modifiedOn || (new Date()).getTime();
    this.context = options && options.context || this.context;
    this.identifier = options && options.identifier || this.identifier;
    this.type = options && options.type || this.type;
    this.contentType = options && options.contentType || this.contentType;
    this.data = options && options.data || this.data;
    this.hashed = options && options.hashed || this.hashed;
    this.value = options && options.value || this.value;
    this._mblVersion = options && options._mblVersion || this._mblVersion;
    moe(this as ObjectEntity<any>);
  }
}

// codes file support
export class CodesFile extends ObjectEntity<CodesFile> implements OE {
  code?: string;
  description?: string;
  name?: string;
  parentId?: number;

  items?: any[];
  levels?: number;

  data?: any;
  value?: any;

  constructor(options?: any) {
    super(options);
    this.code = options && options.code || this.code;
    this.description = options && options.description || this.description;
    this.name = options && options.name || this.name;
    this.parentId = options && options.parentId || this.parentId;
  }

  get hasPayload(): boolean {
    return (this.items && this.items.length > 0);
  }

  get payload(): any[] {
    return this.items;
  }
}

// --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --*-- --->
// --->
// ---> Application Configuration
// --->


export class ApplicationFormBuilderConfiguration {
  page: ApplicationComponentPage;
  route: {
    base: string,
    home: string
  };
  questions: {
    route: {
      base: string;
    };
    config?: {
      answer?: {
        options?: {
          source?: {
            value?: any;
          }
        }
      }
    }
  };
  section: {
    route: {
      base: string,
    }
  };

  constructor(options?: any) {
    this.page = options && options.page;
    this.route = options && options.route;
    this.questions = options && options.questions;
    this.section = options && options.section;
  }

}

export class ApplicationFormCaptureConfiguration {
  page: ApplicationComponentPage;
  route: {
    base: string;
  };
  type?: string;

  constructor(options?: any) {
    this.page = options && options.page || this.page;
    this.route = options && options.route || this.route;
    this.type = options && options.type || this.type || MBL_TYPE_FORM_CAPTURE_V2;
  }

}

export class ApplicationSessionMenuOptionItem {
  label: string;
  link: string;
  enabled: boolean;
  roles?: Array<string>;
}

export class ApplicationSessionMenuGroup {
  label: string;
  items?: Array<ApplicationSessionMenuOptionItem>;
}

export class ApplicationSessionMenuSegment {
  groups?: Array<ApplicationSessionMenuGroup>;
  items?: Array<ApplicationSessionMenuOptionItem>;
}

export class ApplicationSessionMenu {
  options: {
    showUserProfileMenu?: boolean,
    public?: {
      left?: ApplicationSessionMenuSegment,
      right?: ApplicationSessionMenuSegment,
    },
    secure?: {
      left?: ApplicationSessionMenuSegment,
      right?: ApplicationSessionMenuSegment
    },
    user: {
      items?: Array<ApplicationSessionMenuOptionItem>;
    }
  };

  constructor(options?: any) {
    this.options = options && options.options || this.options;
  }
}

export class ApplicationComponentPage {
  breadcrumb?: {
    reset?: boolean,
    label?: string,
    url?: string
  };
  bottom?: {
    text?: string
  };
  description?: string;
  header?: string;
  lead?: string;
  name?: string;
}

export class ApplicationApi {
  host?: string;
  wphost?: string;
  base: string;
  endpoints?: {
    capture?: {
      root?: string,
    },
    content?: {
      file?: string;
      fileSpaces?: string;
      raw?: string;
      root?: string;
      index?: string;
    },
    codes?: string,
    form?: {
      definition?: {
        root: string
      }
    }
    geo?: {
      lookup?: string;
      uid?: string;
      pwd?: string;
      countries?: string;
    };
    gm?: {
      review: string;
      root: string;
    }
    lookup?: string,
    logout?: string,
    reports?: {
      embed: {
        root: string
      }
    },
    self?: string,
    users?: {
      profile?: string,
      root?: string,
      x?: string
      register: {
        path: string,
        msg: string,
      },
    }
  };
  reports: {
    proxy: string
  };
  solrhost?: string;

  constructor(options?: any) {
    this.base = options && options.base || this.base;
    this.endpoints = options && options.endpoints || this.endpoints;
    this.host = options && options.host || this.host;
    this.reports = options && options.reports || this.reports;
    this.solrhost = options && options.solrhost || this.solrhost;
    this.wphost = options && options.wphost || this.wphost;
  }

}

export class User extends ObjectEntity<User> implements OE, Named {

  firstName?: string;
  lastName?: string;
  username?: string = null;
  email?: string;

  picture?: string;

  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  credentialsNonExpired?: boolean;
  enabled?: boolean;
  notes?: string;
  roles?: Array<string> = [];
  userStatus?: string;

  // Out only,  never provided inbound;
  password?: string;
  confirmed?: string;
  ba?: string = null;
  hostUrl?: string;

  public static hasRole(role: string, assignedRoles: Array<string>): boolean {
    if (assignedRoles && assignedRoles.length > 0) {
      for (let i = 0; i < assignedRoles.length; i++) {
        if (assignedRoles[i] === role) {
          return true;
        }
      }
    }
    return false;
  }

  public static hasAnyRole(candidateRoles: Array<string>, assignedRoles: Array<string>): boolean {
    if (candidateRoles && candidateRoles.length > 0) {
      for (let i = 0; i < candidateRoles.length; i++) {
        if (User.hasRole(candidateRoles[i], assignedRoles)) {
          return true;
        }
      }
    }
    return false;
  }

  constructor(options?: any) {
    super(options);
    this.accountNonExpired = options && options.accountNonExpired || this.accountNonExpired;
    this.accountNonLocked = options && options.accountNonLocked || this.accountNonLocked;
    this.ba = options && options.ba || this.ba;
    this.hostUrl = options && options.hostUrl || this.hostUrl;
    this.confirmed = options && options.confirmed || this.confirmed;
    this.credentialsNonExpired = options && options.credentialsNonExpired || this.credentialsNonExpired;
    this.email = options && options.email || this.email;
    this.enabled = options && options.enabled || this.enabled;
    this.firstName = options && options.firstName || this.firstName;
    this.id = options && options.id || this.id;
    this.lastName = options && options.lastName || this.lastName;
    this.notes = options && options.notes || this.notes;
    this.password = options && options.password || this.password;
    this.picture = options && options.picture || this.picture;
    this.roles = options && options.roles || this.roles || ['ROLE_ANONYMOUS'];
    this.username = options && options.username || this.username;
    this.userStatus = options && options.userStatus || this.userStatus;
  }

  get name(): string {
    if (this.firstName && this.lastName) {
      return this.firstName + ' ' + this.lastName;
    }
    return this.username || this.email;
  }

}

export class ApplicationSessionUser {
  auth_token?: string;
  data?: User;
  source?: any;

  constructor(options?: any) {
    this.auth_token = options && options.auth_token || undefined;
    this.data = new User(options && options.data || {username: 'Visitor', roles: ['ROLE_ANONYMOUS']});
    this.source = options && options.source;
  }

  public isAuthenticated(): boolean {
    return !!(this.auth_token
      && this.auth_token.length > 0
      && this.data
      && this.data.enabled
      && this.data.roles
      && this.data.roles.length > 0
      && this.data.username);
  }

  public hasRole(role: string): boolean {
    return this.data
      && this.data.roles
      && this.data.roles.length > 0
      && User.hasRole(role, this.data.roles);
  }

  public hasAnyRole(roles: Array<string>): boolean {
    return this.data
      && this.data.roles
      && this.data.roles.length > 0
      && User.hasAnyRole(roles, this.data.roles);
  }

}

export class ApplicationSession {
  _mblId?: string;

  api?: ApplicationApi;
  m_api?: ApplicationApi;

  auth?: {
    supports?: string[],
    apiEndpoint?: string
  };

  client?: {
    a?: string,
    b?: string,
    c?: string,
  };
  clients?: Array<any>;
  config?: UserInterfaceConfiguration;
  components?: {
    app_configuration?: {
      page: ApplicationComponentPage
    }
    form: {
      builder?: ApplicationFormBuilderConfiguration;
      capture?: ApplicationFormCaptureConfiguration;
    },
    password_reset?: {
      page?: ApplicationComponentPage
    }
  };

  features?: {
    questionCategories: boolean;
    buildKBMSIndex: boolean;
  };
  menu?: ApplicationSessionMenu;
  mabbleUser?: ApplicationSessionUser;
  instance?: {
    state?: BehaviorSubject<InstanceConfiguration>;
    homeContentKey?: string;
    contentKeys?: string[];
    labels?: {
      formBuilder: {
        questionType: {
          options: Array<{ key: string, value: string }>
        },
        answerTypeValue: {
          options: Array<{ key: string, value: string }>
        },
        answerTypeChoice: {
          options: Array<{ key: string, value: string }>
        },
        answerTypeRange: {
          options: Array<{ key: string, value: string }>
        },
        answerTypeFormula: {
          options: Array<{ key: string, value: string }>
        },
        gridRowSource: {
          options: Array<{ key: string, value: string }>
        },
        gridColumnSource: {
          options: Array<{ key: string, value: string }>
        },
        gridAnswerType: {
          options: Array<{ key: string, value: string }>
        }
      }
    }
  };
  defaults?: {
    home?: {
      blurb?: string,
      logo?: string,
      banner?: string,
      route?: string,
      title?: string,
    };
    developer?: {
      anchor?: string,
      blurb?: string
    };
    forgot?: {
      route: string
    };
    footer?: {
      anchor?: string,
      blurb?: string
    };
    login?: {
      x?: string
      logo?: string
      route: string
    };
    logout?: {
      route: string
    };
    post?: {
      login?: {
        x?: string
        y?: string
        z?: string
        route: string
        admin: {
          route: string
        },
        user: {
          route: string
        }
      },
    };
    admin_roles?: string[];
    title: string;
  };
  errors?: {
    login: {
      fail: {
        credentials: string;
      }
    }
  };
  login?: {
    page: {
      breadcrumb: {
        label: string;
        url: string;
      },
      form: {
        model: {
          username: string;
          email: string;
          password: string;
          rememberMe: boolean;
        },
        schema: {
          'properties': {
            username: {
              title: string;
              type: string;
              format: string;
            },
            password: {
              title: string;
              type: string;
              widget: string;
            }
          },
          'required': Array<string>;
          'buttons': Array<{ 'id': string, 'label': string, 'class': string }>;
        }
      },
      name: string;
    }
  };
  version?: {
    major: number;
    minor: number;
    patch: number;
    label: string
  };
  ssitk?: string;

  constructor(options?: any) {
    this._mblId = options && options._mblId;

    this.instance = options && options.instance;
    // if (this.instance) {
    //   this.instance.state = new BehaviorSubject<InstanceConfiguration>(null);
    // }

    this.api = options ? new ApplicationApi(options.api || {}) : new ApplicationApi({});
    this.m_api = options ? new ApplicationApi(options.m_api || {}) : new ApplicationApi({});
    this.auth = options && options.auth;

    this.client = options && options.client || this.client;
    this.clients = options && options.clients || this.clients || [];

    this.components = options && options.components || this.components || {
      form: {
        builder: {},
        capture: {}
      }
    };
    this.components.form.builder =
      options
      && options.components
      && options.components.form
      && options.components.form.builder
      || this.components.form.builder
      || new ApplicationFormBuilderConfiguration({});

    this.components.form.capture =
      options
      && options.components
      && options.components.form
      && options.components.form.capture
      || this.components.form.capture
      || new ApplicationFormCaptureConfiguration({});


    this.config = options ? new UserInterfaceConfiguration(options.config) : new UserInterfaceConfiguration({});
    this.defaults = options && options.defaults;
    this.errors = options && options.errors;
    this.features = options && options.features || {
      questionCategories: false,
      buildKBMSIndex: false
    };
    this.login = options && options.login;
    this.menu = options ? new ApplicationSessionMenu(options.menu) : new ApplicationSessionMenu({});
    // this.user = options ? new ApplicationSessionUser(options.user) : new ApplicationSessionUser({});
    this.mabbleUser = options ? new ApplicationSessionUser(options.mabbleUser) : new ApplicationSessionUser({});
    this.version = options && options.version;
    this.ssitk = options && options.ssitk;

  }
}

// --->
// ---> Module Definition.
// --->
export class MabbleModule<T> extends ObjectEntity<MabbleModule<T>> implements OE {
  description?: string;
  name?: string;
  status?: string;
  accessControl: {
    roles: string[]
  };

  constructor(options?: any) {
    super(options);
    this.accessControl = options && options.accessControl || this.accessControl;
    this.description = options && options.description || this.description;
    this.name = options && options.name || this.name;
    this.status = options && options.status || this.status;
    this.properties = options && options.properties || this.properties || {};
  }

}

export class InstanceConfiguration extends MabbleModule<any> implements OE {
  _statekey = 'zero';
  _state: { [p: string]: InstanceConfiguration } = {'zero': null};

  properties?: {
    instance_top_nav?: MabbleModule<any>[];
    instance_brand_logo_img?: string;
    instance_brand_banner_visible?: boolean;
    instance_brand_banner_img?: string;
    instance_left_sidebar_visible?: boolean;
    instance_left_sidebar?: MabbleModule<any>[];
    instance_left_sidebar_title?: string;
    instance_left_slide_menu?: MabbleModule<any>[];
    instance_right_slide_menu?: MabbleModule<any>[];
    instance_main?: MabbleModule<any>;
  };

  constructor(options?: any) {
    super(options);

    this.properties = options && options.properties || {};
    this.properties.instance_top_nav = options && options.properties && options.properties.instance_top_nav;
    this.properties.instance_brand_logo_img = options && options.properties && options.properties.instance_brand_logo_img;
    this.properties.instance_brand_banner_visible = options && options.properties && options.properties.instance_brand_banner_visible;
    this.properties.instance_brand_banner_img = options && options.properties && options.properties.instance_brand_banner_img;
    this.properties.instance_left_sidebar_visible = options && options.properties && options.properties.instance_left_sidebar_visible;
    this.properties.instance_left_sidebar = options && options.properties && options.properties.instance_left_sidebar;
    this.properties.instance_left_sidebar_title = options && options.properties && options.properties.instance_left_sidebar_title;
    this.properties.instance_left_slide_menu = options && options.properties && options.properties.instance_left_slide_menu;
    this.properties.instance_right_slide_menu = options && options.properties && options.properties.instance_right_slide_menu;
    this.properties.instance_main = options && options.properties && options.properties.instance_main;

    // save until last to get a clean copy
    this.addState('zero', this);
    if (options && options.initialStates) {
      const is: any[] = [...options.initialStates];
      is.forEach(value => this.addState(value.key, value.state));
    }

  }

  set state(k: string) {

    if (k === this._statekey) { // no change;
      return;
    }

    this._state = this._state || {'zero': null};
    if (this._state.zero) {
      this.properties = _.cloneDeep(this._state['zero'].properties);
    }
    if (this._state[k]) {
      this._statekey = k;
      this._state[k].properties = _.merge(this.properties, this._state[k].properties);
    }
  }

  public addState(state: string, payload: InstanceConfiguration): InstanceConfiguration {
    payload['_state'] = undefined;
    this._state = this._state || {'zero': null};
    this._state = _.merge(this._state, {[state]: _.cloneDeep(payload)});
    return this;
  }
}
